import React, { useRef } from 'react';
import bgImage from "../../assets/Images/certificate_bg.png";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Certificate = () => {
  const certificateRef = useRef(null);

  const downloadPDF = async () => {
    const element = certificateRef.current;
    
    // Capture the component as an image using html2canvas
    const canvas = await html2canvas(element, { scale: 2 });
    const imageData = canvas.toDataURL("image/png");

    // Create PDF using jsPDF
    const pdf = new jsPDF("landscape", "pt", "a4");
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = pdf.internal.pageSize.getHeight();

    // Add the captured image to the PDF
    pdf.addImage(imageData, "PNG", 0, 0, pdfWidth, pdfHeight);
    pdf.save("certificate.pdf");
  };

  return (
    <div className="w-full h-[91vh] p-4 bg-white overflow-auto">
      <div
        ref={certificateRef}
        className="h-full"
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        }}
      >
        <div className="p-20 flex flex-col gap-2">
          <h4 className="w-full flex items-center justify-center text-6xl font-serif text-gray-600">
            CERTIFICATE
          </h4>
          <h1 className="w-full flex items-center justify-center text-3xl font-serif italic text-gray-600 pt-4">
            Sachin Kumar
          </h1>
          <div className="w-full flex items-center justify-center text-md font-serif text-gray-600 pt-1">
            successfully completed
          </div>
          <div className="w-full flex items-center justify-center text-2xl font-serif text-gray-600 pt-1">
            Exam Title
          </div>
          <div className="w-full flex items-center justify-center text-md font-serif text-gray-600 pt-1">
            on
          </div>
          <div className="w-full flex items-center justify-center text-xl font-serif text-gray-600 pt-1">
            9/12/2024
          </div>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
