import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const profileApi = createApi({
  reducerPath: "profileApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://chatbot.samparkme.com",
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem("userToken"));
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    //Get profile details
    getProfileDetails: builder.mutation({
      query: (userId) => ({
        url: "/document-management/user/auth/fetch/profile/details",
        method: "POST",
        body: {
          request: {
            requestData: {
              userId: userId,
            },
          },
        },
      }),
    }),
  }),
});

export const { useGetProfileDetailsMutation } = profileApi;
