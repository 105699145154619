import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import Avatar from "@mui/material/Avatar";
import { Link } from "react-router-dom";
import { logout } from "../../redux/slice/auth/authSlice";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import { IoIosCode } from "react-icons/io";
import { toggleBotBuild } from "../../redux/slice/common/commonSlice";
import { useLocation } from "react-router-dom";
import { resetStore } from "../../redux/slice/common/resetSlice";
import { useGetProfileDetailsMutation } from "../../api/profileApi";

const Navbar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [getProfileDetails] = useGetProfileDetailsMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const isSideBarOpen = useSelector((state) => state.common.isSideBarOpen);
  const profileData = JSON.parse(localStorage.getItem("userProfileData"));
  const userData = JSON.parse(localStorage.getItem("userData"));
  const pathName = location.pathname.endsWith("/dashboard");
  const hasFetchedData = useRef(false);

  const fetchProfileDetail = async () => {
    try {
      let response = await getProfileDetails(userData.customerId).unwrap();
      if (response.status && response.statusCode === 200) {
        localStorage.setItem(
          "userProfileData",
          JSON.stringify(response.responseObject.profileDetails)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (!hasFetchedData.current) {
      fetchProfileDetail();
      hasFetchedData.current = true;
    }
  }, []);

  const handleLogout = () => {
    localStorage.clear();
    dispatch(resetStore());
    dispatch(logout());
  };

  const handleProfileMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleProfileMenuClose = () => {
    setAnchorEl(null);
  };

  return (
    <div
      className="Navbar h-[57px] bg-white fixed flex justify-between items-center border-b z-[999]"
      style={{
        width: isSideBarOpen ? "85%" : "95%",
        padding: "0px 18px 0px 16px",
      }}
    >
      <h4 className="font-semibold text-base text-[#585e6c]">
        SamBot Dashboard
      </h4>
      <div className="navbar_right flex items-center gap-5">
        {pathName && (
          <div className="right_navbar">
            <button
              className="h-[40px] rounded-md border-solid flex items-center justify-center gap-1 bg-blue-600 px-3"
              onClick={() => dispatch(toggleBotBuild())}
            >
              <IoIosCode className="text-base text-white" />
              <span className="text-white font-semibold text-sm">
                Build Bot
              </span>
            </button>
          </div>
        )}
        <h4 className="text-[14px] font-medium text-[#585e6c]">
          <b>Welcome:</b> {profileData?.firstName + " " + profileData?.lastName}
        </h4>
        <Tooltip title="Account settings" arrow>
          <IconButton
            onClick={handleProfileMenuClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? "account-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
          >
            <Avatar sx={{ width: 32, height: 32 }}>
              {userData.firstName.split("")[0]}
            </Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          anchorEl={anchorEl}
          id="account-menu"
          open={open}
          onClose={handleProfileMenuClose}
          PaperProps={{
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&::before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          }}
          transformOrigin={{ horizontal: "right", vertical: "top" }}
          anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
        >
          <Link to="profile" onClick={handleProfileMenuClose}>
            <MenuItem>
              <Avatar sx={{ width: 32, height: 32 }}>
                {userData.firstName.split("")[0]}
              </Avatar>
              Profile
            </MenuItem>
          </Link>
          <Divider />
          {/* <Link to="userdetails" onClick={handleProfileMenuClose}>
            <MenuItem>
              <ListItemIcon>
                <PersonAdd fontSize="small" />
              </ListItemIcon>
              Add user
            </MenuItem>
          </Link> */}

          <Link onClick={handleLogout} to="/auth/*">
            <MenuItem>
              <ListItemIcon>
                <Logout fontSize="small" />
              </ListItemIcon>
              Logout
            </MenuItem>
          </Link>
        </Menu>
      </div>
    </div>
  );
};

export default Navbar;
