import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import userAuthReducer from "./slice/auth/authSlice";
import commonReducer from "./slice/common/commonSlice";
import chatReducer from "./slice/chat/chatSlice";
import nodeSliceReducer from "./slice/Flows/nodeSlice";
import edgeSliceReducer from "./slice/Flows/edgeSlice";
import intentReducer from "./slice/Flows/intentsSlice";
import connectionReducer from "./slice/Flows/connectionSlice";
import modalReducer from "./slice/common/commonModalsSlice";
import { generateAnswer } from "../chatbot/services/api";
import { authApi } from "../api/authApi";
import { userApi } from "../api/userApi";
import { flowApi } from "../api/flowApi";
import { documentApi } from "../api/documentApi";
import { departmentApi } from "../api/departmentApi";
import { botApi } from "../api/botApi";
import { profileApi } from "../api/profileApi";
import { multiLangApi } from "../api/multilingualApi";
import { examApi } from "../api/examApi";
import resetReducer, { resetStore } from "./slice/common/resetSlice";

const appReducer = combineReducers({
  chat: chatReducer,
  auth: userAuthReducer,
  common: commonReducer,
  nodes: nodeSliceReducer,
  edges: edgeSliceReducer,
  connections: connectionReducer,
  modal: modalReducer,
  intents: intentReducer,
  [generateAnswer.reducerPath]: generateAnswer.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [userApi.reducerPath]: userApi.reducer,
  [flowApi.reducerPath]: flowApi.reducer,
  [documentApi.reducerPath]: documentApi.reducer,
  [departmentApi.reducerPath]: departmentApi.reducer,
  [botApi.reducerPath]: botApi.reducer,
  [profileApi.reducerPath]: profileApi.reducer,
  [multiLangApi.reducerPath]: multiLangApi.reducer,
  [examApi.reducerPath]: examApi.reducer,
  reset: resetReducer,
});

const rootReducer = (state, action) => {
  if (action.type === resetStore.type) {
    state = undefined;
  }
  return appReducer(state, action);
};

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      generateAnswer.middleware,
      authApi.middleware,
      userApi.middleware,
      flowApi.middleware,
      documentApi.middleware,
      departmentApi.middleware,
      botApi.middleware,
      profileApi.middleware,
      multiLangApi.middleware,
      examApi.middleware,
    ]),
});

setupListeners(store.dispatch);
export default store;
