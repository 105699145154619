import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ requiredRole }) => {
  const isLoggIn = useSelector((state) => state.auth.isLoggIn);
  const userRole = useSelector((state) => state.auth.user.role);

  if (!isLoggIn) {
    return <Navigate to="/auth/login" replace />;
  }
  const effectiveRole = userRole !== "ORG ADMIN" ? "USER" : "ORG ADMIN";
  if (!requiredRole.includes(effectiveRole)) {
    return <Navigate to="/" replace />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
