// redux/slice/auth/authSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isLoggIn: false,
  user: {
    role: null,
  },
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    login(state, action) {
      state.isLoggIn = true;
      state.user = { role: JSON.parse(localStorage.getItem("userData"))?.role };
    },
    logout(state) {
      state.isLoggIn = false;
      state.user = { role: null };
    },
  },
});

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
