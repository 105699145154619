import React, { useEffect, lazy, Suspense, useMemo } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import ProtectedRoute from "./ProtectedRoute";
import Sidebar from "../components/common/Sidebar";
import Navbar from "../components/common/Navbar";
import Loader from "../components/common/Loader";

const Dashboard = lazy(() => import("../pages/dashboard/Dashboard"));
const ManageUser = lazy(() => import("../pages/ManageUser"));
const About = lazy(() => import("../pages/About"));
const UploadDocument = lazy(() => import("../pages/UploadDocument"));
const History = lazy(() => import("../components/user/History"));
const Flow = lazy(() => import("../pages/Flow"));
const Profile = lazy(() => import("../pages/Profile"));
const Intents = lazy(() => import("../pages/Intents"));
const CreateBot = lazy(() => import("../pages/dashboard/CreateBot"));
const Department = lazy(() => import("../pages/department/Department"));
const SubDepartment = lazy(() => import("../pages/department/SubDepartment"));

const AuthenticatedRoutes = () => {
  const location = useLocation();
  const isSideBarOpen = useSelector((state) => state.common.isSideBarOpen);
  const currentPath = location.pathname;

  const sidebarWidth = useMemo(
    () => (isSideBarOpen ? "15%" : "5%"),
    [isSideBarOpen]
  );
  const mainWidth = useMemo(
    () => (isSideBarOpen ? "85%" : "95%"),
    [isSideBarOpen]
  );

  useEffect(() => {
    if (currentPath !== "/department/category") {
      sessionStorage.removeItem("department");
    }
    if (
      currentPath !== "/dashboard" &&
      currentPath !== "/department/category"
    ) {
      sessionStorage.removeItem("botData");
    }
  }, [location]);

  return (
    <div className="flex h-screen">
      <div
        className="bg-gray-100"
        style={{ width: sidebarWidth, transition: "width 0.3s linear" }}
      >
        <Sidebar />
      </div>
      <div className="bg-gray-200 overflow-y-auto" style={{ width: mainWidth }}>
        <Navbar />
        <div className="flex flex-col mt-[50px]">
          <Suspense fallback={<Loader />}>
            <Routes>
              <Route path="/" element={<CreateBot />} />

              {/* Common routes for both ORG ADMIN and USER */}
              <Route
                element={
                  <ProtectedRoute requiredRole={["ORG ADMIN", "USER"]} />
                }
              >
                <Route path="/about" element={<About />} />
                <Route path="/profile" element={<Profile />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route
                  path="/department/category"
                  element={<SubDepartment />}
                />
              </Route>

              {/* Routes for ORG ADMIN */}
              <Route element={<ProtectedRoute requiredRole={["ORG ADMIN"]} />}>
                <Route path="/userdetails" element={<ManageUser />} />
                <Route path="/upload/document" element={<UploadDocument />} />
                <Route path="/history" element={<History />} />
                <Route path="/create/flow" element={<Flow />} />
                <Route path="/create/intents" element={<Intents />} />
                <Route path="/department" element={<Department />} />
              </Route>

              <Route path="*" element={<Navigate to="/" replace />} />
            </Routes>
          </Suspense>
        </div>
      </div>
    </div>
  );
};

export default AuthenticatedRoutes;
