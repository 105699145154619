import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const departmentApi = createApi({
  reducerPath: "departmentApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://chatbot.samparkme.com",
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem("userToken"));
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    //Get departments
    getDepartment: builder.query({
      query: () => ({
        url: "/document-management/common/fetch/category/master",
        method: "GET",
      }),
    }),

    //Create department
    createDepartment: builder.mutation({
      query: (data) => ({
        url: "/document-management/common/save/category",
        method: "POST",
        body: {
          request: {
            requestData: {
              categoryName: data,
            },
          },
        },
      }),
    }),

    //Delete department
    deleteDepartment: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/delete/category",
        method: "POST",
        body: {
          request: {
            requestData: {
              categoryId: id,
            },
          },
        },
      }),
    }),

    //Update department
    updateDepartment: builder.mutation({
      query: (data) => ({
        url: "/document-management/common/update/category",
        method: "POST",
        body: {
          request: {
            requestData: data,
          },
        },
      }),
    }),

    //Get sub-deparment
    getSubDepartment: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/fetch/subcategory/master",
        method: "POST",
        body: {
          request: {
            requestData: {
              categoryId: id,
            },
          },
        },
      }),
    }),

    //Create sub-category
    createSubDepartment: builder.mutation({
      query: (data) => ({
        url: "/document-management/common/save/subcategory",
        method: "POST",
        body: {
          request: {
            requestData: data,
          },
        },
      }),
    }),

    //Delete sub-department
    deleteSubDepartment: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/delete/subcategory",
        method: "POST",
        body: {
          request: {
            requestData: {
              subCategoryId: id,
            },
          },
        },
      }),
    }),

    //Update sub-department
    updateSubDepartment: builder.mutation({
      query: (data) => ({
        url: "/document-management/common/update/subcategory",
        method: "POST",
        body: {
          request: {
            requestData: data,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetDepartmentQuery,
  useCreateDepartmentMutation,
  useDeleteDepartmentMutation,
  useUpdateDepartmentMutation,
  useGetSubDepartmentMutation,
  useCreateSubDepartmentMutation,
  useDeleteSubDepartmentMutation,
  useUpdateSubDepartmentMutation,
} = departmentApi;
