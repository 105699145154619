import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isSideBarOpen: false,
  isLoading: false,
  isBotBuildOpen: false,
  botLang: "en",
};

const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    toggleSideBar(state, action) {
      state.isSideBarOpen = !state.isSideBarOpen;
    },
    setLoading(state, action) {
      state.isLoading = action.payload;
    },
    toggleBotBuild(state, action) {
      state.isBotBuildOpen = !state.isBotBuildOpen;
    },
    setBotLang(state, action) {
      state.botLang = action.payload;
    },
  },
});

export const { toggleSideBar, setLoading, toggleBotBuild, setBotLang } =
  commonSlice.actions;
export default commonSlice.reducer;
