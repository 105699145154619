import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://chatbot.samparkme.com",
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem("userToken"));
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    //create new user
    createNewUser: builder.mutation({
      query: ({ formValues, selectedDepartments, selectedRole }) => ({
        url: "/document-management/user/auth/addUser",
        method: "POST",
        body: {
          request: {
            requestData: {
              firstName: formValues.fName,
              lastName: formValues.lName,
              mobileNo: formValues.mobileNo,
              email: formValues.email,
              password: formValues.password,
              personType: selectedRole,
              departmentList: selectedDepartments,
            },
          },
        },
      }),
    }),
    //update user
    updateUser: builder.mutation({
      query: (data) => ({
        url: "/document-management/user/auth/update/profile",
        method: "POST",
        body: {
          request: {
            requestData: data,
          },
        },
      }),
    }),
    // delete user
    deleteUser: builder.mutation({
      query: (data) => ({
        url: "/document-management/user/auth/delete/user",
        method: "POST",
        body: {
          request: {
            requestData: {
              personId: data,
            },
          },
        },
      }),
    }),
    //get all user
    getAllUser: builder.mutation({
      query: (data) => ({
        url: "/document-management/user/auth/user/list",
        method: "POST",
        body: {
          request: {
            requestData: {},
          },
        },
      }),
    }),
    //get all roles
    getAllRoles: builder.query({
      query: () => "/document-management/user/auth/fetch/roles",
    }),
  }),
});

export const {
  useCreateNewUserMutation,
  useDeleteUserMutation,
  useUpdateUserMutation,
  useGetAllUserMutation,
  useGetAllRolesQuery,
} = userApi;
