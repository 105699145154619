import React, { useEffect, lazy, Suspense, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login } from "./redux/slice/auth/authSlice";
import AuthenticatedRoutes from "./routes/AuthenticatedRoutes";
import UnauthenticatedRoutes from "./routes/UnauthenticatedRoutes";
import "./App.css";

function App() {
  const isLoggIn = useSelector((state) => state.auth.isLoggIn);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn");
    if (storedIsLoggedIn === "true") {
      const userData = JSON.parse(localStorage.getItem("userData"));
      dispatch(login(userData));
      navigate(location.pathname);
    }
  }, [dispatch, location.pathname, navigate]);

  return (
    <div className="App">
      {isLoggIn ? <AuthenticatedRoutes /> : <UnauthenticatedRoutes />}
    </div>
  );
}

export default App;
