import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeTab: 1,
  intentModal: false,
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    },
    setIntentModal: (state, action) => {
      state.intentModal = !state.intentModal;
    },
  },
});

export const { setActiveTab, setIntentModal } = modalSlice.actions;
export default modalSlice.reducer;
