import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://chatbot.samparkme.com",
  }),
  endpoints: (builder) => ({
    //user signup
    signUp: builder.mutation({
      query: (data) => ({
        url: "/document-management/admin/register/organization",
        method: "POST",
        body: {
          request: {
            requestData: {
              orgName: data.organization,
              firstName: data.fName,
              lastName: data.lName,
              mobileNo: data.mobileNo,
              email: data.email,
              password: data.password,
            },
          },
        },
      }),
    }),
    //user signin
    signIn: builder.mutation({
      query: (data) => ({
        url: "/document-management/user/auth/authenticatByEmail",
        method: "POST",
        body: {
          request: {
            requestData: {
              email: data.email,
              password: data.password,
            },
          },
        },
      }),
    }),
  }),
});

export const { useSignUpMutation, useSignInMutation } = authApi;
