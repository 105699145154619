import { createSlice } from "@reduxjs/toolkit";
import { uniqueId } from "../../../utils/uniqueIdGenerator";
import { MarkerType } from "reactflow";

const initialState = {
  edges: [],
};

const edgeSlice = createSlice({
  name: "edges",
  initialState,
  reducers: {
    addEdge: (state, action) => {
      state.edges.push(action.payload);
    },
    connectEdge: (state, action) => {
      const { id, target, sourceNodeId, targetNodeId } = action.payload;
      state.edges.push({
        id: uniqueId(),
        source: id,
        target,
        sourceHandle: sourceNodeId,
        targetHandle: targetNodeId,
        type: "default",
        markerEnd: { type: MarkerType.Arrow },
      });
    },
    deleteEdge: (state, action) => {
      state.edges = state.edges.filter((edge) => edge.id !== action.payload);
    },
  },
});

export const { addEdge, connectEdge, deleteEdge } = edgeSlice.actions;
export default edgeSlice.reducer;
