import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const botApi = createApi({
  reducerPath: "botApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://chatbot.samparkme.com",
    prepareHeaders: (headers) => {
      const token = JSON.parse(localStorage.getItem("userToken"));
      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    //Get bots
    getBots: builder.query({
      query: () => "/document-management/common/fetch/chat/bot",
    }),

    //Create chatbot
    createBot: builder.mutation({
      query: (data) => ({
        url: "/document-management/common/create/chat/bot",
        method: "POST",
        body: {
          request: {
            requestData: data,
          },
        },
      }),
    }),

    // //Delete chatbot
    deleteBot: builder.mutation({
      query: (id) => ({
        url: "/document-management/common/delete/chat/bot",
        method: "POST",
        body: {
          request: {
            requestData: {
              botId: id,
            },
          },
        },
      }),
    }),

    //Update chatbot
    updateBot: builder.mutation({
      query: (data) => ({
        url: "/document-management/common/update/chat/bot",
        method: "POST",
        body: {
          request: {
            requestData: data,
          },
        },
      }),
    }),
  }),
});

export const {
  useGetBotsQuery,
  useCreateBotMutation,
  useDeleteBotMutation,
  useUpdateBotMutation,
} = botApi;
