import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const chat = createSlice({
  name: "chat",
  initialState,
  reducers: {
    addMessage: (state, action) => {
      state.push(action.payload);
    },
    clearChat: () => initialState,
  },
});

export const { addMessage, clearChat } = chat.actions;
export default chat.reducer;
