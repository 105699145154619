import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  connections: [],
};
const connectionSlice = createSlice({
  name: "connections",
  initialState,
  reducers: {
    addConnection: (state, action) => {
      state.connections.push(action.payload);
    },
    removeConnection: (state, action) => {
      state.connections = state.connections.filter(
        (edge) => edge.id !== action.payload.id
      );
    },
  },
});

export const { addConnection, removeConnection } = connectionSlice.actions;
export default connectionSlice.reducer;
