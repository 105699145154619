import { createSlice } from "@reduxjs/toolkit";
import { uniqueId } from "../../../utils/uniqueIdGenerator";

const initialState = {
  intents: [],
  selectedIntent: {
    name: "",
    utterances: [""],
  },
};

const intentsSlice = createSlice({
  name: "intents",
  initialState,
  reducers: {
    addIntent: (state, action) => {
      const { name, utterances = [""] } = action.payload;
      state.intents = [
        ...state.intents,
        {
          name,
          utterances,
        },
      ];
    },
    updateIntent: (state, action) => {
      const { name, utterances } = action.payload;
      let intent = state.intents.find((idx) => idx.name === name);
      if (intent) {
        intent.utterances = utterances || intent.utterances;
      }
    },
    setSelectedIntent: (state, action) => {
      let intent = state.intents.find((idx) => idx.name === action.payload);
      state.selectedIntent = intent;
    },
  },
});

export const { addIntent, updateIntent, setSelectedIntent } =
  intentsSlice.actions;
export default intentsSlice.reducer;
