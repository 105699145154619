import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const multiLangApi = createApi({
  reducerPath: "multiLangApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "https://dhruva-api.bhashini.gov.in/",
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      headers.set(
        "Authorization",
        "TgW1EmYTICEfmGV4qKMXXedHoMJmGsDQbstdx1R1bRy_vUFi0LXDeo7QXuOmWaKI"
      );

      return headers;
    },
  }),
  endpoints: (builder) => ({
    convertTextLang: builder.mutation({
      query: (data) => ({
        url: "services/inference/pipeline",
        method: "POST",
        body: data,
      }),
    }),
  }),
});

export const { useConvertTextLangMutation } = multiLangApi;
