import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const flowApi = createApi({
  reducerPath: "flowApi",
  baseQuery: fetchBaseQuery({
    baseUrl: "http://localhost:3000/",
  }),
  endpoints: (builder) => ({
    getNodes: builder.query({
      query: () => "nodes",
    }),
    getEdges: builder.query({
      query: () => "edges",
    }),
    getIntents: builder.query({
      query: () => "intents",
    }),
    createNode: builder.mutation({
      query: (data) => ({
        url: "create/flow",
        method: "POST",
        body: data,
      }),
    }),
    updateNode: builder.mutation({
      query: (data) => ({
        url: `update/flow/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteNode: builder.mutation({
      query: (id) => ({
        url: `delete/flow/${id}`,
        method: "DELETE",
      }),
    }),
    createIntent: builder.mutation({
      query: (data) => ({
        url: "create/intent",
        method: "POST",
        body: data,
      }),
    }),
    updateIntent: builder.mutation({
      query: (data) => ({
        url: `update/intent/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteIntent: builder.mutation({
      query: (id) => ({
        url: `delete/intent/${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetNodesQuery,
  useGetEdgesQuery,
  useGetIntentsQuery,
  useCreateNodeMutation,
  useUpdateNodeMutation,
  useDeleteNodeMutation,
  useCreateIntentMutation,
  useUpdateIntentMutation,
  useDeleteIntentMutation,
} = flowApi;
