import { createSlice } from "@reduxjs/toolkit";
import { randomNodeName } from "../../../utils/randomNodeName";
import { MarkerType } from "reactflow";
import { uniqueId } from "../../../utils/uniqueIdGenerator";

const initialState = {
  nodes: [
    {
      id: uniqueId(),
      type: "customNode",
      data: {
        label: `node-${randomNodeName(6)}`,
        intents: ["default"],
        onEnterText: [],
        onRecieveText: [],
        isWait: false,
        isStartNode: true,
        isEndNode: false,
      },
      position: { x: 200, y: 5 },
      markerEnd: {
        type: MarkerType.Arrow,
      },
    },
  ],
  selectedNode: {},
};

const nodeSlice = createSlice({
  name: "nodes",
  initialState,
  reducers: {
    setSelectedNode: (state, action) => {
      state.selectedNode = action.payload;
    },
    setNodes: (state, action) => {
      state.nodes.push(action.payload);
    },
    setOnEnterText: (state, action) => {
      const { id, text, selectedIntent } = action.payload;
      state.nodes = state.nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                onEnterText: node.data.onEnterText.includes(text)
                  ? node.data.onEnterText
                  : [
                      ...node.data.onEnterText,
                      {
                        textId: uniqueId(),
                        text: text,
                      },
                    ],
              },
            }
          : node
      );
    },
    setOnRecieveText: (state, action) => {
      const { id, text } = action.payload;
      state.nodes = state.nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                onRecieve: node.data.onRecieve.includes(text)
                  ? node.data.onRecieve
                  : [
                      ...node.data.onRecieve,
                      {
                        textId: uniqueId(),
                        text: text,
                      },
                    ],
              },
            }
          : node
      );
    },
    deletNode: (state, action) => {
      state.nodes = state.nodes.filter((node) => node.id !== action.payload);
    },
    setIsUserWait: (state, action) => {
      const { id, isWait } = action.payload;
      state.nodes = state.nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                isWait,
              },
            }
          : node
      );
    },
    addIntentToNode: (state, action) => {
      const { id, intent } = action.payload;
      state.nodes = state.nodes.map((node) =>
        node.id === id
          ? {
              ...node,
              data: {
                ...node.data,
                intents: node.data.intents.includes(intent)
                  ? node.data.intents
                  : [...node.data.intents, intent],
              },
            }
          : node
      );
    },
  },
});

export const {
  setSelectedNode,
  setNodes,
  setOnEnterText,
  setOnRecieveText,
  deletNode,
  setIsUserWait,
  addIntentToNode,
} = nodeSlice.actions;
export default nodeSlice.reducer;
